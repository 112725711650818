<template>
  <div>
    <div class="grid-x grid-margin-x">
      <div class="cell small-12 medium-6 large-4">
        <data-card
          v-bind:card-id="'clientsDataCards'"
          v-bind:theme="dataCards.all.theme"
          v-bind:name="dataCards.all.name"
          v-bind:value="dataCards.all.value | numFormat"
        >
          <message-icon />
        </data-card>
      </div>
      <div class="cell small-12 medium-6 large-4">
        <data-card
          v-bind:card-id="'clientsDataCards'"
          v-bind:theme="dataCards.new.theme"
          v-bind:name="dataCards.new.name"
          v-bind:value="dataCards.new.value | numFormat"
        >
          <message-icon />
        </data-card>
      </div>
      <div class="cell small-12 medium-6 large-4">
        <data-card
          v-bind:card-id="'clientsDataCards'"
          v-bind:theme="dataCards.rebound.theme"
          v-bind:name="dataCards.rebound.name"
          v-bind:value="dataCards.rebound.value | numFormat"
        >
          <message-icon />
        </data-card>
      </div>
      <div class="cell small-12 medium-6 large-4">
        <data-card
          v-bind:card-id="'clientsDataCards'"
          v-bind:theme="dataCards.bounce.theme"
          v-bind:name="dataCards.bounce.name"
          v-bind:suffix="'%'"
          v-bind:value="dataCards.bounce.value | numFormat('0.00')"
        >
          <message-icon />
        </data-card>
      </div>
    </div>

    <div class="grid-x grid-margin-x">
      <div class="cell small-12 medium-12">
        <data-chart
          ref="agentsByCategories"
          v-bind:paginate="true"
          v-bind:per-page="8"
          v-bind:name="'chart.agents.by-categories'"
          v-bind:chart-id="'agentsByCategories'"
          v-bind:chart-data="dataCharts.agentsByCategories"
          v-bind:type="'stackedColumn'"
          v-on:change="chartRangeChanged"
        />
      </div>
      <div class="cell small-12 medium-12">
        <data-table
          v-bind:translatable="true"
          v-bind:formats="tableAgentsKpi.formats"
          v-bind:table-id="'agentsKpi'"
          v-bind:name="'chart.agents.kpi'"
          v-bind:headers="tableAgentsKpi.headers"
          v-bind:rows="tableAgentsKpi.rows"
        />
      </div>
      <div class="cell small-12 medium-12">
        <data-chart
          ref="agentsTotal"
          v-bind:filter-series="true"
          v-bind:placeholder="$t('dashboard.filter.agents-placeholder')"
          v-bind:name="'chart.agents.total'"
          v-bind:chart-id="'agentsTotal'"
          v-bind:chart-data="dataCharts.agentsTotal"
          v-bind:type="'line'"
          v-on:change="chartRangeChanged"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ClientsDataCards from '@/graphql/queries/clients/dataCards.gql';
import AgentsByCategories from '@/graphql/queries/agents/byCategories.gql';
import AgentsKpi from '@/graphql/queries/agents/kpi.gql';
import AgentsTotal from '@/graphql/queries/agents/total.gql';
import executeQuery from '@/utils/gql-api';

export default {
  components: {
    DataCard: () => import('@/components/DataCard.vue'),
    DataChart: () => import('@/components/DataChart.vue'),
    DataTable: () => import('@/components/DataTable.vue'),
  },
  props: {
    filters: {
      required: true,
    },
  },
  data() {
    return {
      tableAgentsKpi: {
        headers: [],
        rows: [],
        formats: [
          false,
          'number',
          'time',
          'time',
        ],
      },
      dataCards: {
        all: {
          theme: 'blue',
          name: 'chart.card.clients-all',
          value: 0,
        },
        new: {
          theme: 'yellow',
          name: 'chart.card.clients-new',
          value: 0,
        },
        rebound: {
          theme: 'purple',
          name: 'chart.card.clients-rebound',
          value: 0,
        },
        bounce: {
          theme: 'red',
          name: 'chart.card.clients-bounce',
          value: 0,
        },
      },
      dataCharts: {
        agentsByCategories: null,
        agentsTotal: null,
      },
    };
  },
  watch: {
    filters(newValue) {
      if (newValue !== null) {
        this.initAll();
      }
    },
  },
  mounted() {
    if (this.filters !== null) {
      this.initAll();
    }
  },
  methods: {
    initAll() {
      this.loadCards();
      this.loadAgentsKpi();
      this.loadAgentsByCategories();
      this.loadAgentsTotal();
      this.resetFiltersFromCharts();
    },
    resetFiltersFromCharts() {
      if (this.$refs.agentsByCategories) {
        this.$refs.agentsByCategories.resetFilters();
      }

      if (this.$refs.agentsTotal) {
        this.$refs.agentsTotal.resetFilters();
      }
    },
    async loadCards() {
      const data = await this.fetchData('clientsDataCards', ClientsDataCards, this.filters);

      this.dataCards.all.value = data.all;
      this.dataCards.new.value = data.new;
      this.dataCards.rebound.value = data.rebound;
      this.dataCards.bounce.value = data.all > 0 ? (data.rebound / data.all * 100) : 0;
    },
    async loadAgentsKpi() {
      const data = await this.fetchData('agentsKpi', AgentsKpi, this.filters);

      this.tableAgentsKpi.headers = data.categories;
      this.tableAgentsKpi.rows = data.series;
    },
    async loadAgentsByCategories(paramFilters = {}) {
      const filters = { ...this.filters, ...paramFilters };
      this.dataCharts.agentsByCategories = await this.fetchData('agentsByCategories', AgentsByCategories, filters);
    },
    async loadAgentsTotal(paramFilters = {}) {
      const filters = { ...this.filters, ...paramFilters };
      this.dataCharts.agentsTotal = await this.fetchData('agentsTotal', AgentsTotal, filters);
    },
    async fetchData(id, query, variables = {}) {
      this.$store.commit('dashboard/enableLoader', id);
      const data = await executeQuery(id, query, variables);
      this.$store.commit('dashboard/disableLoader', id);

      return data;
    },
    chartRangeChanged(chartId, from, to) {
      if (from !== null && to !== null) {
        const startDate = this.$moment(from)
          .format('YYYY-MM-DD');
        const endDate = this.$moment(to)
          .format('YYYY-MM-DD');

        const filters = {
          startDate,
          endDate,
        };

        switch (chartId) {
          case 'agentsByCategories':
            this.loadAgentsByCategories(filters);
            break;
          case 'agentsTotal':
            this.loadAgentsTotal(filters);
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>
